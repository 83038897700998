@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.inter-100 {
  font-weight: 100;
}

.inter-200 {
  font-weight: 200;
}

.inter-300 {
  font-weight: 300;
}

.inter-500 {
  font-weight: 500 !important;
}

.inter-600 {
  font-weight: 600 !important;
}

.inter-700 {
  font-weight: 700;
}

.inter-800 {
  font-weight: 800;
}

.inter-900 {
  font-weight: 900;
}

html {
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Segoe UI", "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scrollbar-width: thin;
}

/*Start | Style*/
:root {
  --bs-primary: #0065FF;
  --bs-primary-rgb: 0, 101, 255;
  --bs-body-color: #152033;
  --bs-body-color-rgb: 21, 32, 51;
  --bs-success-rgb: 83, 166, 83;
}

.l {
  box-shadow: 0 0 0 1px red;
}

.i {
  box-shadow: inset 0 0 0 1px blue;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: -0.1em;
}

.ts-0p7 {
  transform: scale(0.7);
}

.ts-0p8 {
  transform: scale(0.8);
}

.ts-0p9 {
  transform: scale(0.9);
}

.ts-1p1 {
  transform: scale(1.1);
}

.ts-1p2 {
  transform: scale(1.2);
}

.ts-1p3 {
  transform: scale(1.3);
}

.ts-1p4 {
  transform: scale(1.4);
}

.ts-1p5 {
  transform: scale(1.5);
}

.ts-1p6 {
  transform: scale(1.6);
}

.ts-1p7 {
  transform: scale(1.7);
}

.ts-1p8 {
  transform: scale(1.8);
}

.ts-1p9 {
  transform: scale(1.9);
}

.ts-2p0 {
  transform: scale(2);
}

.ts-2p1 {
  transform: scale(2.1);
}

.ts-2p2 {
  transform: scale(2.2);
}

.fs-10px {
  font-size: 10px;
}

.fs-11px {
  font-size: 11px;
}

.fs-12px {
  font-size: 12px;
}

.fs-13px {
  font-size: 13px;
}

.fs-14px {
  font-size: 14px;
}

.fs-16px {
  font-size: 16px;
}

.fs-18px {
  font-size: 18px;
}

.text-blue {
  color: #0065FF !important;
}

.text-b100 {
  color: #777E89 !important;
}

.text-b200 {
  color: #3D4656 !important;
}

.text-b300 {
  color: #152033 !important;
}

.text-success300 {
  color: #53A653 !important;
}

.text-warning200 {
  color: #F7AE35 !important;
}

.text-warning300 {
  color: #F59E0C !important;
}

.text-error300 {
  color: #EF4040 !important;
}

.text-cyan {
  color: #14B8A6 !important;
}

.text-pink {
  color: #EC4899 !important;
}

.text-purple {
  color: #6366F1 !important;
}

.text-yellow {
  color: #FACC15 !important;
}

.text-orange {
  color: #FF8800 !important;
}

.text-green {
  color: #53A653 !important;
}

.text-red {
  color: #EF4040 !important;
}

.text-hover-primary:hover {
  color: #0065FF !important;
}

.bg-gray {
  background-color: #F6F7F9;
}

.bg-gray-50 {
  background-color: rgba(246, 247, 249, 0.5);
}

.bg-b50 {
  background-color: #E8E9EB;
}

.bg-b75 {
  background-color: #9FA4AB !important;
}

.bg-b100 {
  background-color: #777E89 !important;
}

.bg-blue50 {
  background-color: #E6F0FF !important;
}

.bg-blue75 {
  background-color: #96C0FF !important;
}

.bg-primary-10, .bg-blue-10 {
  background-color: rgba(0, 101, 255, 0.1) !important;
}

.bg-warning-10 {
  background-color: rgba(245, 158, 12, 0.1) !important;
}

.bg-cyan-10 {
  background-color: rgba(20, 184, 166, 0.1) !important;
}

.bg-pink-10 {
  background-color: rgba(236, 72, 153, 0.1) !important;
}

.bg-purple-10 {
  background-color: rgba(99, 102, 241, 0.1) !important;
}

.bg-yellow-10 {
  background-color: rgba(250, 204, 21, 0.1) !important;
}

.bg-orange-10 {
  background-color: rgba(255, 136, 0, 0.1) !important;
}

.bg-orange-15 {
  background-color: rgba(255, 136, 0, 0.15) !important;
}

.bg-green-10 {
  background-color: rgba(83, 166, 83, 0.1) !important;
}

.bg-red-10 {
  background-color: rgba(239, 64, 64, 0.1) !important;
}

.border-common {
  --bs-border-color: #EFF0F4;
}

.text-unof-1 {
  text-underline-offset: 1px;
}

.text-unof-2 {
  text-underline-offset: 2px;
}

.text-unof-3 {
  text-underline-offset: 3px;
}

.text-link {
  text-decoration: none;
  color: #0065FF;
  text-underline-offset: 4px;
}
.text-link:hover {
  color: #044bb5;
  text-decoration: underline;
}

.minw-75px {
  min-width: 75px;
}

.minh-40px {
  min-height: 40px;
}

.minh-200px {
  min-height: 200px;
}

.minh-205px {
  min-height: 205px;
}

.minh-208px {
  min-height: 208px;
}

.minh-210px {
  min-height: 210px;
}

.minh-218px {
  min-height: 218px;
}

.minh-220px {
  min-height: 220px;
}

.maxw-120 {
  max-width: 120px;
}

.maxw-150 {
  max-width: 150px;
}

.maxw-160 {
  max-width: 160px;
}

.maxw-180 {
  max-width: 180px;
}

.maxw-200 {
  max-width: 200px;
}

.maxw-220 {
  max-width: 220px;
}

.maxw-560px {
  max-width: 560px;
}

.mmwh-25 {
  min-height: 25px;
  max-height: 25px;
  min-width: 25px;
  max-width: 25px;
}

.mmwh-45 {
  min-height: 45px;
  max-height: 45px;
  min-width: 45px;
  max-width: 45px;
}

.mmwh-48 {
  min-height: 48px;
  max-height: 48px;
  min-width: 48px;
  max-width: 48px;
}

.mmwh-120 {
  min-height: 120px;
  max-height: 120px;
  min-width: 120px;
  max-width: 120px;
}

.mmw-16 {
  min-width: 16px;
  max-width: 16px;
}

.mmw-100 {
  min-width: 100px;
  max-width: 100px;
}

.mmw-200 {
  min-width: 200px;
  max-width: 200px;
}

.mmw-220 {
  min-width: 220px;
  max-width: 220px;
}

.mmw-240 {
  min-width: 240px;
  max-width: 240px;
}

.mmw-280 {
  min-width: 280px;
  max-width: 280px;
}

.mmh-15 {
  min-height: 15px;
  max-height: 15px;
}

.mmh-16 {
  min-height: 16px;
  max-height: 16px;
}

.py-2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.py-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}

.p-20px {
  padding: 20px;
}

.img-cover {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.rounded-10px {
  border-radius: 10px !important;
}

.rounded-20px {
  border-radius: 20px;
}

.shadow-common {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.02);
}

.dropdown-item-common {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #3D4656;
  position: relative;
}
.dropdown-item-common:active {
  background: #0065FF;
  color: #ffffff;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-15 {
  opacity: 0.15;
}

.form-control-fin {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  height: 50px;
  border-radius: 8px;
  background-color: #F6F7F9;
  border-color: #F6F7F9;
}
.form-control-fin::-moz-placeholder {
  color: #9FA4AB;
}
.form-control-fin::placeholder {
  color: #9FA4AB;
}
.form-control-fin:focus {
  background-color: #F6F7F9;
}

.form-search {
  padding-left: 45px !important;
  vertical-align: -0.125em;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23757575' xmlns='http://www.w3.org/2000/svg'><path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0'/></svg>");
  background-repeat: no-repeat;
  background-size: 1.15rem 1.15rem;
  background-position: 13px center;
}

.control-withicon {
  position: relative;
}
.control-withicon .control-icon {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  border-radius: 4px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
}
.control-withicon .control-icon .icon {
  font-size: 20px;
  color: #0065FF;
}
.control-withicon .form-control-fin {
  padding-left: 60px;
}
.control-withicon:has(.control-onoffpassword) .form-control-fin {
  padding-right: 52px;
}
.control-withicon .control-onoffpassword {
  position: absolute;
  padding: 0;
  border: none;
  right: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  background-color: transparent;
  color: #9FA4AB;
}
.control-withicon .control-onoffpassword:hover, .control-withicon .control-onoffpassword:focus {
  color: #0D141F;
}

.btn-primary {
  --fin-primary-rgb: 49,132,253;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #0065FF;
  --bs-btn-border-color: #0065FF;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #0a58ca;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: var(--fin-primary-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #044bb5;
  --bs-btn-active-border-color: #044bb5;
}

.btn-outline-primary {
  --bs-btn-color: #0065FF;
  --bs-btn-border-color: #0065FF;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0065FF;
  --bs-btn-hover-border-color: #0065FF;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #044bb5;
  --bs-btn-active-border-color: #044bb5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0065FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0065FF;
  --bs-gradient: none;
}

.btn-fin {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  height: 50px;
  border-radius: 8px;
}

.btn-normal {
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  --bs-border-radius: 0.625rem;
  --bs-btn-padding-x: 0.885rem;
}

.form-control-select {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  height: 50px;
  border-radius: 8px;
  background-color: #F6F7F9;
  border-color: #F6F7F9;
}
.form-control-select div[class*=-control] {
  border: none !important;
  outline: none;
  box-shadow: none;
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding-left: 6px;
}
.form-control-select div[class*=-control]:focus-within {
  box-shadow: 0 0 0 1px #86b7fe;
}

/* Start | Asidebar Style */
.fin-aside {
  position: fixed;
  width: 240px;
  height: 100%;
  left: 0;
  top: 0;
  background: #F6F7F9;
  display: flex;
  flex-direction: column;
  z-index: 11;
  transition: 250ms;
}
.fin-aside .fin-logobox {
  padding: 0 16px;
  min-height: 66px;
  height: 66px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.fin-aside .fin-logobox::before {
  content: "";
  width: calc(100% - 32px);
  height: 1px;
  background-color: #E8E9EB;
  position: absolute;
  left: 16px;
  bottom: 0;
}
.fin-aside .fin-logobox a {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 6px 16px;
}
.fin-aside .fin-logobox .fin-menutoggler {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 0;
  border: none;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #E8E9EB;
  right: -12px;
  top: 21px;
  color: #777E89;
  font-size: 22px;
  overflow: hidden;
  z-index: 2;
  transition: 300ms;
}
.fin-aside .fin-logobox .fin-menutoggler:hover, .fin-aside .fin-logobox .fin-menutoggler:focus-visible {
  color: #0065FF;
  box-shadow: 0 0 4px 1px #0065FF;
}
.fin-aside .fin-asideinner {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 16px 16px;
  scrollbar-width: thin;
}
.fin-aside .fin-asidelabel {
  text-transform: uppercase;
  font-size: 14px;
  color: #9FA4AB;
  font-weight: 500;
  padding-left: 16px;
  margin-bottom: 12px;
}
.fin-aside .fin-mainmenu {
  margin-bottom: 50px;
}
.fin-aside .fin-othermenu {
  margin-top: auto;
}
.fin-aside .fin-asidelink {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 40px;
  padding: 5px 16px;
  border-radius: 6px;
  gap: 12px;
  transition: 200ms;
  color: #3D4656;
  text-decoration: none;
}
.fin-aside .fin-asidelink .icon {
  width: 21px;
  height: 21px;
}
.fin-aside .fin-asidelink:not(:last-child) {
  margin-bottom: 10px;
}
.fin-aside .fin-asidelink:hover {
  color: #0065FF;
  background: rgba(0, 101, 255, 0.08);
}
.fin-aside .fin-asidelink.active {
  color: #0065FF;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
}
.fin-aside .fin-asidelink[href="/login"] {
  color: #EF4040;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
}
.fin-aside .fin-asidelink[href="/login"]:hover {
  opacity: 0.75;
}
.fin-aside .fin-asidelink[href="/login"]:active {
  opacity: 1;
}

/* End | Asidebar */
/* Start | Header */
.fin-header {
  width: 100%;
  height: 66px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding-left: 274px;
  padding-right: 24px;
  z-index: 10;
  box-shadow: 264px 0 1px 1px #EFF0F4;
  transition: 250ms;
}
.fin-header .fin-headerleft {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  color: #152033;
  font-weight: 500;
  gap: 8px;
}
.fin-header .fin-headerright {
  display: flex;
  align-items: center;
  gap: 24px;
}
.fin-header .dropdown-notifications .btn-bell {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #F6F7F9;
  border: none;
  position: relative;
  font-size: 19px;
}
.fin-header .dropdown-notifications .btn-bell::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 6px;
  background-color: #F26060;
  z-index: 1;
  top: 9px;
  right: 11px;
  visibility: hidden;
}
.fin-header .dropdown-notifications .btn-bell.active::before {
  visibility: visible;
}
.fin-header .dropdown-notifications .dropdown-menu {
  width: 440px;
  border-radius: 16px;
  border: none;
  margin-top: 12px !important;
  padding: 0;
  overflow: hidden;
}
.fin-header .dropdown-notifications .notif-card {
  display: flex;
  flex-direction: column;
}
.fin-header .dropdown-notifications .notif-card .notif-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EBEDF0;
}
.fin-header .dropdown-notifications .notif-card .notif-header strong {
  color: #152033;
  font-size: 20px;
  font-weight: 600;
}
.fin-header .dropdown-notifications .notif-card .notif-header span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #0065FF;
  background-color: #E6F0FF;
}
.fin-header .dropdown-notifications .notif-card .notif-header .notif-markread {
  font-size: 16px;
  font-weight: 600;
  color: #0065FF;
  background-color: transparent;
  border: none;
  padding: 2px 0;
  text-underline-offset: 4px;
}
.fin-header .dropdown-notifications .notif-card .notif-header .notif-markread:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.fin-header .dropdown-notifications .notif-card .notif-body {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 220px);
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item {
  text-decoration: none;
  display: flex;
  gap: 12px;
  padding: 20px;
  position: relative;
  white-space: normal;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item:not(:last-child)::after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  left: 20px;
  bottom: 0px;
  border-bottom: 1px solid #EFF0F4;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item.notif-new .notif-icon {
  position: relative;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item.notif-new .notif-icon::before {
  visibility: visible;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item:active {
  background: #0065FF;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item:active * {
  color: #ffffff !important;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item .notif-icon {
  position: relative;
  color: #0065FF;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item .notif-icon::before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: currentColor;
  visibility: hidden;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item .notif-icon .notif-iconmask {
  width: 48px;
  min-width: 48px;
  height: 48px;
  overflow: hidden;
  padding: 8px;
  background-color: #E8E9EB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item .notif-icon .notif-iconmask img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item p {
  font-size: 16px;
  color: #091E42;
  margin-bottom: 10px;
}
.fin-header .dropdown-notifications .notif-card .notif-body .dropdown-item small {
  font-size: 14px;
  font-weight: 600;
  color: #777E89;
}
.fin-header .dropdown-profile .dropdown-toggle {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
}
.fin-header .dropdown-profile .dropdown-toggle[aria-expanded=true] .avatar-mask {
  padding: 2px;
  background-color: rgba(0, 101, 255, 0.75);
}
.fin-header .dropdown-profile .dropdown-toggle .avatar-mask {
  overflow: hidden;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 1px;
}
.fin-header .dropdown-profile .dropdown-toggle .avatar-mask img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.fin-header .dropdown-profile .dropdown-toggle strong {
  font-size: 14px;
  font-weight: 600;
}
.fin-header .dropdown-profile .dropdown-menu {
  width: 250px;
  border-radius: 16px;
  border: none;
  margin-top: 12px !important;
  padding: 0;
}
.fin-header .dropdown-profile .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #3D4656;
  position: relative;
}
.fin-header .dropdown-profile .dropdown-menu .dropdown-item:active {
  background: #0065FF;
  color: #ffffff;
}
.fin-header .dropdown-profile .dropdown-menu .dropdown-item .icon {
  font-size: 19px;
}
.fin-header .dropdown-profile .dropdown-menu .dropdown-item.arrow::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  opacity: 0.6;
  right: 20px;
  transform: rotate(45deg);
  top: calc(50% - 5px);
}

.global-search {
  position: relative;
  width: 262px;
}
.global-search .search-icon {
  position: absolute;
  pointer-events: none;
  font-size: 18px;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  color: #777E89;
}
.global-search .form-control {
  border-radius: 10px;
  height: 40px;
  font-weight: 500;
  padding-left: 40px;
  padding-right: 64px;
  background-color: #F6F7F9;
  border-color: #F6F7F9;
  font-size: 14px;
}
.global-search .form-control:focus {
  border-color: rgba(0, 101, 255, 0.25);
}
.global-search .form-control::-moz-placeholder {
  color: #777E89;
}
.global-search .form-control::placeholder {
  color: #777E89;
}
.global-search .command-button {
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 4px;
  border-radius: 8px;
  background: transparent;
  top: 50%;
  height: calc(100% - 8px);
  transform: translateY(-50%);
  color: #152033;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #ffffff;
  padding: 2px 8px;
  font-size: 17px;
  line-height: 1;
}
.global-search .command-button span {
  font-size: 15px;
  font-family: "Inter", "Segoe UI", "Roboto", sans-serif;
  font-weight: 500;
}

/* End | Header */
.fin-mainwrapper {
  padding-top: 66px;
  padding-left: 240px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  transition: 250ms;
}
.fin-mainwrapper .fin-innerwrapper {
  height: auto;
  padding: 24px 12px;
}
.fin-mainwrapper .fin-innerwrapper .row-inner {
  margin-left: 0;
  margin-right: 0;
}
.fin-mainwrapper .fin-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 24px;
  border-top: 1px solid #EFF0F4;
  height: 56px;
  background: #fff;
  margin-top: auto;
  color: #777E89;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.01), 0 -7px 7px rgba(0, 0, 0, 0.01), 0 -12px 9px rgba(0, 0, 0, 0.01);
}
.fin-mainwrapper .fin-footer .fin-footerlink {
  display: flex;
  gap: 6px 24px;
}
.fin-mainwrapper .fin-footer .fin-footerlink a {
  text-decoration: none;
  transition: 250ms;
  color: #777E89;
}
.fin-mainwrapper .fin-footer .fin-footerlink a:hover {
  color: #0065FF;
}

/*Start | Menu Responsive*/
@media screen and (min-width: 1024.9px) {
  .body-menuactive .fin-aside {
    left: -228px;
  }
  .body-menuactive .fin-aside .fin-logobox .fin-menutoggler {
    transform: rotate(180deg);
  }
  .body-menuactive .fin-aside .fin-asideinner {
    overflow-y: hidden;
  }
  .body-menuactive .fin-header {
    padding-left: 34px;
    box-shadow: 24px 0 1px 1px #EFF0F4;
  }
  .body-menuactive .fin-mainwrapper {
    padding-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .fin-aside {
    left: -228px;
  }
  .fin-aside .fin-logobox .fin-menutoggler {
    transform: rotate(180deg);
  }
  .fin-aside .fin-asideinner {
    overflow-y: hidden;
  }
  .fin-header {
    padding-left: 34px;
    box-shadow: 24px 0 1px 1px #EFF0F4;
  }
  .fin-mainwrapper {
    padding-left: 0;
  }
  .body-menuactive .fin-aside {
    left: 0;
  }
  .body-menuactive .fin-aside .fin-logobox .fin-menutoggler {
    transform: rotate(0deg);
  }
  .body-menuactive .fin-aside .fin-asideinner {
    overflow-y: auto;
  }
}
/*End | Menu Responsive*/
.simulator-bg {
  background-color: rgba(230, 240, 255, 0.3);
  border-top: 2px solid #96C0FF;
}

.label-withicon {
  position: relative;
  display: block;
}
.label-withicon label {
  display: flex;
  gap: 10px;
  font-size: 14px;
  color: #777E89;
  font-weight: 500;
}
.label-withicon label .icon {
  min-width: 20px;
  width: 20px;
  height: 20px;
}
.label-withicon label span {
  align-self: center;
}
.label-withicon .label-inputwrapper {
  position: relative;
  padding-left: 30px;
}
.label-withicon .label-inputwrapper input {
  width: 100%;
  height: 38px;
  padding: 0 20px 0 0;
  border: 0;
  border-bottom: 1px solid #9FA4AB;
  font-size: 16px;
  font-weight: 600;
  color: #152033;
  outline: none;
  background: transparent;
}
.label-withicon .label-inputwrapper input:focus {
  border-bottom: 1px solid #0065FF;
}

.table-responsive {
  padding: 1.25px 1.25px 2px 1.25px;
  scrollbar-width: thin;
}

.table-common {
  border-collapse: collapse;
  border-radius: 12px;
  box-shadow: 0 0 0 1.15px #EFF0F4;
  width: 100%;
}
.table-common thead {
  background-color: rgba(230, 240, 255, 0.3019607843);
}
.table-common thead th, .table-common thead td {
  font-size: 12.5px;
  line-height: 18px;
  font-weight: 600;
  padding: 12px 20px;
  border-bottom: 1px solid #EFF0F4;
  color: rgba(0, 101, 255, 0.72);
  vertical-align: middle;
}
.table-common thead th:first-child, .table-common thead td:first-child {
  border-radius: 12px 0 0 0;
}
.table-common thead th:last-child, .table-common thead td:last-child {
  border-radius: 0 12px 0 0;
}
.table-common tbody {
  font-size: 14px;
}
.table-common tbody tr:not(:last-child) {
  border-bottom: 1px solid #EFF0F4;
}
.table-common tbody tr:not(:last-child) td:first-child::before, .table-common tbody tr:not(:last-child) td:last-child::before {
  content: "";
  width: 20px;
  height: 2px;
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #fff;
}
.table-common tbody tr:not(:last-child) td:last-child::before {
  left: auto;
  right: 0;
}
.table-common tbody tr td {
  color: #3D4656;
  padding: 15px 20px;
  font-weight: 500;
  position: relative;
}
.table-common tfoot {
  background-color: rgba(230, 240, 255, 0.3019607843);
  font-size: 14px;
}
.table-common tfoot td {
  color: #152033;
  padding: 15px 20px;
}

.link-newstext {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: currentColor;
  text-decoration: none;
}
.link-newstext:hover {
  color: #0065FF;
}

/* Start | Login */
.entry-section {
  position: relative;
  display: flex;
  min-height: 100vh;
}
.entry-section .entry-leftbox {
  width: 100%;
  max-width: 600px;
  background-color: #F6F7F9;
  padding: 68px;
  position: relative;
}
.entry-section .entry-leftbox .entry-bgpattern {
  position: absolute;
  z-index: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0;
  left: 0;
}
.entry-section .entry-leftbox .entry-logo {
  position: relative;
}
.entry-section .entry-leftbox h1 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #152033;
  margin-bottom: 20px;
}
.entry-section .entry-leftbox h1 span {
  color: #0065FF;
}
.entry-section .entry-leftbox p {
  font-size: 16px;
  color: #3D4656;
  text-align: center;
  margin: 0;
}
.entry-section .entry-rightbox {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.entry-section .entry-rightbox .entry-controls {
  width: 100%;
  max-width: 540px;
}
.entry-section .entry-rightbox .er-heading {
  font-size: 36px;
  font-weight: 600;
  color: #152033;
}
.entry-section .entry-rightbox .er-paragraph {
  font-size: 15px;
  color: #777E89;
}

.entry-pointbox {
  text-align: center;
  max-width: 90px;
}
.entry-pointbox span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}
.entry-pointbox span img {
  width: 30px;
  display: block;
  filter: invert(47%) sepia(74%) saturate(7494%) hue-rotate(209deg) brightness(98%) contrast(113%);
}
.entry-pointbox small {
  margin-top: 8px;
  display: block;
  font-size: 12px;
  color: #777E89;
  font-weight: 500;
}

.or-line {
  position: relative;
  overflow: hidden;
  text-align: center;
  display: block;
  color: #9FA4AB;
}
.or-line::before, .or-line::after {
  content: "";
  width: calc(50% - 24px);
  height: 1px;
  top: 50%;
  display: block;
  position: absolute;
  background-color: #EFF0F4;
}
.or-line::after {
  left: auto;
  right: 0;
}

@media screen and (max-height: 768px) {
  .entry-section .entry-leftbox {
    max-width: 500px;
    padding: 24px 60px;
  }
  .entry-section .entry-leftbox .ratio-1x1 {
    width: 64%;
    margin: 0 auto;
  }
  .entry-section .entry-leftbox h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .entry-section .entry-leftbox p {
    font-size: 14px;
  }
}
/* End | Login */
.nav-pills-common {
  border-radius: 10px;
  background-color: #F6F7F9;
  padding: 6px 10px;
  gap: 6px 12px;
}
.nav-pills-common .nav-link {
  --bs-nav-link-padding-y: 3.5px;
  font-size: 14px;
  color: #777E89;
  font-weight: 500;
  border-radius: 6px;
}
.nav-pills-common .nav-link:hover {
  color: #152033;
  box-shadow: inset 0 0 0 1px #9FA4AB;
}
.nav-pills-common .nav-link.active {
  background-color: #fff;
  color: #152033;
  box-shadow: none;
  pointer-events: none;
}

.nav-pills-tabs {
  gap: 10px;
}
.nav-pills-tabs .nav-link {
  background-color: #f6f7f9;
  font-size: 14px;
  color: #777E89;
  font-weight: 500;
  border-radius: 6px;
}
.nav-pills-tabs .nav-link.active {
  pointer-events: none;
}

.input-group-datepicker {
  position: relative;
}
.input-group-datepicker .form-control {
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  color: #152033;
  border-radius: 10px;
  padding-left: 44px;
  border-color: #E8E9EB;
}
.input-group-datepicker .form-control:focus {
  border-color: #86b7fe;
}
.input-group-datepicker .icon {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 6;
  font-size: 21px;
  pointer-events: none;
}
.input-group-datepicker .btn {
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  color: #152033;
  border-radius: 10px;
  border-color: #E8E9EB;
}
.input-group-datepicker .btn:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.input-group-datepicker .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: #3D4656;
}
.input-group-datepicker .dropdown-item:active {
  color: #fff;
}

/*Start | Chart Style*/
.fin-donutchart {
  position: relative;
  min-width: 200px;
  width: 200px;
  height: 200px;
}
.fin-donutchart .fin-innerdonuttext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.fin-donutchart .fin-innerdonuttext p {
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  color: #777E89;
  margin-bottom: 5px;
}
.fin-donutchart .fin-innerdonuttext h4 {
  font-size: 29px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

.fin-allfillchart {
  min-width: 160px;
  width: 160px;
  height: 160px;
  margin: 0 auto;
  position: relative;
}

.fin-donutdivider {
  width: 1px;
  height: 160px;
  background-color: #E8E9EB;
}

.apexcharts-toolbar {
  z-index: 2 !important;
}

/*End | Chart Style*/
.pagination {
  align-items: center;
  font-family: "Inter", "Segoe UI", "Roboto", sans-serif;
}
.pagination .page-item, .pagination .ant-pagination-item {
  font-family: "Inter", "Segoe UI", "Roboto", sans-serif;
  height: auto;
  border-radius: 2.5px;
  line-height: normal;
  margin-inline-end: 6px;
  font-weight: 600;
  background-color: transparent;
}
.pagination .page-item.page-item-prev, .pagination .page-item.page-item-next, .pagination .ant-pagination-item.page-item-prev, .pagination .ant-pagination-item.page-item-next {
  height: auto;
}
.pagination .page-item.page-item-prev .page-link, .pagination .page-item.page-item-next .page-link, .pagination .ant-pagination-item.page-item-prev .page-link, .pagination .ant-pagination-item.page-item-next .page-link {
  background: transparent;
  border-color: transparent;
}
.pagination .page-item .page-link, .pagination .page-item a, .pagination .ant-pagination-item .page-link, .pagination .ant-pagination-item a {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 2.5px;
  font-size: 12px;
  font-weight: 600 !important;
  padding: 6px;
  text-align: center;
  min-width: 32px;
  border-color: #0065FF;
}
.pagination .page-item .page-link:hover, .pagination .page-item a:hover, .pagination .ant-pagination-item .page-link:hover, .pagination .ant-pagination-item a:hover {
  color: #0065FF;
  background-color: rgba(0, 101, 255, 0.1);
}
.pagination .ant-pagination-prev, .pagination .ant-pagination-next {
  height: auto;
  line-height: normal;
  border-radius: 2.5px;
}
.pagination .ant-pagination-prev .page-link, .pagination .ant-pagination-prev a, .pagination .ant-pagination-next .page-link, .pagination .ant-pagination-next a {
  font-size: 12px;
  font-weight: 600;
  background: transparent;
  border-color: transparent;
  border-radius: 2.5px;
}
.pagination .ant-pagination-item-active {
  border-color: transparent;
}
.pagination .ant-pagination-item-active .page-link, .pagination .ant-pagination-item-active a {
  background-color: #0065FF;
  color: #fff;
}
.pagination .ant-pagination-disabled {
  pointer-events: none;
}

.row-cols-border .col {
  border: 1px solid #E8E9EB;
  margin-right: -1px;
  position: relative;
}
.row-cols-border .col.col-indicator {
  cursor: pointer;
}
.row-cols-border .col.col-indicator:hover {
  background-color: #F6F7F9;
}
.row-cols-border .col::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: -1px;
  left: 0;
  background-color: transparent;
  transition: 300ms;
}
.row-cols-border .col::after {
  content: "";
  width: 0;
  height: 0;
  left: calc(50% - 10px);
  bottom: -9px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  transition: 100ms;
}
.row-cols-border .col.col-myporfolio::before {
  background-color: #F59E0C;
}
.row-cols-border .col.col-myporfolio::after {
  border-top: 10px solid #F59E0C;
}
.row-cols-border .col.col-nifty::before {
  background-color: #0065FF;
}
.row-cols-border .col.col-nifty::after {
  border-top: 10px solid #0065FF;
}

.profile-feature-card {
  position: relative;
  padding: 20px 60px 20px 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.02);
  display: flex;
  gap: 15px;
  height: 100%;
}
.profile-feature-card .pfc-icon {
  min-width: 45px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.profile-feature-card .pfc-icon .icon {
  font-size: 24px;
}
.profile-feature-card .pfc-content h5 {
  font-size: 18px;
  font-weight: 600;
  color: #152033;
  margin-bottom: 4px;
}
.profile-feature-card .pfc-content p {
  color: #777E89;
  font-size: 14px;
  margin: 0;
}
.profile-feature-card .pfc-content .pfc-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  background-color: #F6F7F9;
  color: #3D4656;
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  transition: 300ms;
  border: none;
  padding: 0;
}
.profile-feature-card .pfc-content .pfc-link:hover, .profile-feature-card .pfc-content .pfc-link:focus-visible {
  background-color: #0065FF;
  color: #fff;
}

.checked-medium-text .form-check {
  cursor: pointer;
}
.checked-medium-text .form-check .form-check-input:checked ~ .form-check-label {
  font-weight: 500;
  color: #152033;
}

@media screen and (min-width: 992px) {
  .minw-md-280 {
    min-width: 280px;
  }
  .minw-md-300 {
    min-width: 300px;
  }
  .minw-md-320 {
    min-width: 320px;
  }
}
.css-tooltip {
  --bg-ttp: #000000;
  position: relative;
  font-family: Arial;
  outline: none;
}
.css-tooltip[aria-label=Copied] {
  --bg-ttp: #3A743A;
  color: #3A743A;
}
.css-tooltip:focus-visible {
  opacity: 0.75;
}
.css-tooltip:hover::before, .css-tooltip:hover::after, .css-tooltip:focus-visible::before, .css-tooltip:focus-visible::after {
  visibility: visible;
  opacity: 1;
}
.css-tooltip::before {
  content: attr(aria-label);
  position: absolute;
  min-width: 40px;
  white-space: nowrap;
  font-size: 13px;
  text-align: center;
  min-height: 28px;
  background-color: var(--bg-ttp);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  line-height: 1;
  border-radius: 6px;
  text-shadow: none;
  padding: 7px 10px;
  left: 50%;
  bottom: calc(100% + 8px);
  transform: translate(-50%, 0);
  z-index: 2;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}
.css-tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--bg-ttp);
  left: 50%;
  bottom: calc(100% + 2px);
  transform: translate(-50%, 0);
  z-index: 2;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.card-detailing {
  position: relative;
  border-radius: 12px;
  background-color: #F6F7F9;
  padding: 18px;
  height: 100%;
  box-shadow: 0 0 0 1px #F6F7F9;
}
.card-detailing .card-icon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 24px;
}
.card-detailing h5 {
  padding-top: 1px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3px;
}
.card-detailing p {
  font-size: 12.5px;
  font-weight: 500;
  color: #777E89;
}
.card-detailing hr {
  opacity: 0.15;
}
.card-detailing.card-detailtheme {
  background-color: #F2F6FF;
  box-shadow: 0 0 0 1px rgba(93, 137, 238, 0.15);
}
.card-detailing.card-detailtheme .card-icon {
  background-color: #E0EAFF;
  color: #5D89EE;
}
.card-detailing.card-detailtheme h5, .card-detailing.card-detailtheme hr {
  color: #5D89EE;
}
.card-detailing.card-detailpink {
  background-color: #FFF2F3;
  box-shadow: 0 0 0 1px rgba(234, 130, 139, 0.15);
}
.card-detailing.card-detailpink .card-icon {
  background-color: #FFE5E7;
  color: #EA828B;
}
.card-detailing.card-detailpink h5, .card-detailing.card-detailpink hr {
  color: #EA828B;
}
.card-detailing.card-detailpurple {
  background-color: #FAF4FF;
  box-shadow: 0 0 0 1px rgba(190, 142, 229, 0.15);
}
.card-detailing.card-detailpurple .card-icon {
  background-color: #F3E5FF;
  color: #BE8EE5;
}
.card-detailing.card-detailpurple h5, .card-detailing.card-detailpurple hr {
  color: #BE8EE5;
}
.card-detailing.card-detailgreen {
  background-color: #EFFFF7;
  box-shadow: 0 0 0 1px rgba(131, 198, 164, 0.15);
}
.card-detailing.card-detailgreen .card-icon {
  background-color: #DBF9EA;
  color: #83C6A4;
}
.card-detailing.card-detailgreen h5, .card-detailing.card-detailgreen hr {
  color: #83C6A4;
}
.card-detailing.card-detailgold {
  background-color: #FFFDF3;
  box-shadow: 0 0 0 1px rgba(222, 201, 99, 0.15);
}
.card-detailing.card-detailgold .card-icon {
  background-color: #F9F3CE;
  color: #DEC963;
}
.card-detailing.card-detailgold h5, .card-detailing.card-detailgold hr {
  color: #DEC963;
}

.annual-budgetbox {
  position: relative;
  display: flex;
  align-items: center;
}
.annual-budgetbox .annual-incomebox {
  width: 220px;
  height: 220px;
  border-radius: 162px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 2;
  position: relative;
  border: 14px solid #14B8A6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.annual-budgetbox .annual-incomebox small {
  color: #152033;
  font-size: 15px;
  margin: -10px;
  padding-left: 14px;
}
.annul-incomebox-vs {
 display: flex;
 align-items: center;
 padding: 5px;
 margin-right: 5px;
}
.Estimates-div-item h6{
  padding-top: 20px;
}
.vs-text{
  padding-right: 5px;
}
.text-anuual{
  margin-left: 3px;
}

.annual-budgetbox .annual-incomebox h6 {
  color: #14B8A6;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
}
.annual-budgetbox .ab-divider {
  width: 180px;
}
.annual-budgetbox .sed-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.annual-budgetbox .ab-linebox {
  position: relative;
  padding-top: 4px;
}
.annual-budgetbox .ab-linebox small {
  font-size: 11px;
  color: #3D4656;
  white-space: nowrap;
}
.annual-budgetbox .ab-linebox h6 {
  font-size: 13px;
  font-weight: 700;
  color: #152033;
  margin: 0;
}
.annual-budgetbox .ab-linebox::before, .annual-budgetbox .ab-linebox::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
  border-radius: 10px;
  background-color: #c6c6cc;
}
.annual-budgetbox .ab-linebox::after {
  width: 34px;
  background-color: #3a3a3b;
  z-index: 1;
}
.annual-budgetbox .ab-linebox.ab-success::before {
  background-color: #B8DBB8;
}
.annual-budgetbox .ab-linebox.ab-success::after {
  background-color: #70B570;
}
.annual-budgetbox .ab-linebox.ab-error::before {
  background-color: #F8B1B1;
}
.annual-budgetbox .ab-linebox.ab-error::after {
  background-color: #F26060;
}
.annual-budgetbox .ab-linebox.ab-warning::before {
  background-color: #FBD79B;
}
.annual-budgetbox .ab-linebox.ab-warning::after {
  background-color: #F7AE35;
}
.annual-budgetbox .sed-lines {
  font-size: 14px;
  position: absolute;
  text-align: center;
  color: #152033;
  border-bottom: 2px dashed currentColor;
  padding: 4px 0;
}
.annual-budgetbox .sed-lines::after {
  content: "";
  position: absolute;
  right: 0;
  top: calc(100% - 5px);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 9px solid currentColor;
}
.annual-budgetbox .sed-badge {
  width: 100px;
  height: 100px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #999;
  border: 3px solid currentColor;
  position: absolute;
  top: calc(50% - 40px);
  left: -50px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.annual-budgetbox .savings-box, .annual-budgetbox .expenses-box, .annual-budgetbox .debt-box {
  position: relative;
  min-height: 148px;
  border-radius: 10px;
  padding: 18px 18px 18px 55px;
}
.annual-budgetbox .savings-box {
  background-color: #EEF6EE;
}
.annual-budgetbox .savings-box .sed-lines {
  border-bottom: 2px dashed #53A653;
  transform: rotate(-21deg);
  width: 240px;
  left: -265px;
  top: 64%;
}
.annual-budgetbox .savings-box .sed-lines::after {
  color: #53A653;
}
.annual-budgetbox .savings-box .sed-badge {
  color: #53A653;
}
.annual-budgetbox .expenses-box {
  background-color: #FDECEC;
}
.annual-budgetbox .expenses-box .sed-lines {
  border-bottom: 2px dashed #EF4040;
  transform: rotate(0deg);
  width: 200px;
  left: -228px;
  top: calc(50% - 30px);
  padding-left: 50px;
}
.annual-budgetbox .expenses-box .sed-lines::after {
  color: #EF4040;
}
.annual-budgetbox .expenses-box .sed-badge {
  color: #EF4040;
}
.annual-budgetbox .debt-box {
  background-color: #FEF5E7;
}
.annual-budgetbox .debt-box .sed-lines {
  border-bottom: 2px dashed #F59E0C;
  transform: rotate(21deg);
  width: 240px;
  left: -254px;
  top: -6%;
  padding-left: 50px;
}
.annual-budgetbox .debt-box .sed-lines::after {
  color: #F59E0C;
}
.annual-budgetbox .debt-box .sed-badge {
  color: #F59E0C;
}

.goals-progressbar {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}
.goals-progressbar .progress {
  border-radius: 40px;
  height: 20px;
}
.goals-progressbar .progress-savings {
  background-color: #B8DBB8;
}
.goals-progressbar .progress-savings .progress-bar {
  background-color: #70B570;
}
.goals-progressbar .progress-expenses {
  background-color: #F8B1B1;
}
.goals-progressbar .progress-expenses .progress-bar {
  background-color: #F26060;
}
.goals-progressbar .progress-debt {
  background-color: #FBD79B;
}
.goals-progressbar .progress-debt .progress-bar {
  background-color: #F7AE35;
}

.card-common {
  border-color: #EFF0F4;
}
.card-common .card-header {
  border-color: #EFF0F4;
  padding-top: 16px;
  padding-bottom: 15px;
}
.card-common .card-header .card-title {
  color: #152033;
}

.card-listing {
  border: none;
  border-radius: 12px;
  overflow: hidden;
}
.card-listing .card-header {
  border: none;
  background-color: #F6F7F9;
  border-radius: 12px 12px 0 0;
}
.card-listing .card-header small {
  text-transform: uppercase;
  font-size: 13px;
  color: #777E89;
  font-weight: 500;
}
.card-listing .card-body {
  border: 1px solid #EFF0F4;
  border-top: none;
  border-radius: 0 0 12px 12px;
}
.card-listing .card-body .list-group-item {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  color: #152033;
}
.card-listing .card-body .list-group-item .list-group-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-size: 17px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.card-listing .card-body .list-group-item span {
  font-size: 14px;
  font-weight: 500;
}
.card-listing .card-body .list-group-item strong {
  align-self: center;
  font-size: 15px;
  line-height: 1;
  font-weight: 600;
}

.meter-wrapper {
  width: 140px;
  height: 140px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding: 2px;
}
.meter-wrapper svg {
  width: 100%;
  height: 100%;
  transform: rotate(135deg);
}
.meter-wrapper .meter-circle {
  fill: none;
}
.meter-wrapper .meter-outline {
  stroke: #F6F7F9;
}
.meter-wrapper .meter-range {
  stroke: currentColor;
  transition: 0.5s;
}
.meter-wrapper .meter-niddledot {
  background: currentColor;
  width: 14px;
  height: 14px;
  border-radius: 20px;
  position: absolute;
  bottom: 41%;
  left: calc(50% - 7px);
  transform: rotate(230deg);
  z-index: 2;
  transition: 0.5s;
}
.meter-wrapper .meter-niddle {
  position: absolute;
  bottom: 5px;
  left: calc(50% - 4px);
  width: 1px;
  border: 4px solid currentColor;
  border-right-color: transparent;
  border-left-color: transparent;
  border-width: 0px 4px 40px;
}
.meter-wrapper .meter-min, .meter-wrapper .meter-max {
  display: inline-block;
  position: absolute;
  z-index: 4;
  color: #777E89;
  font-size: 11.5px;
  line-height: 1;
  font-weight: 600;
  left: 13%;
  bottom: 4%;
}
.meter-wrapper .meter-max {
  left: unset;
  right: 11%;
}
.meter-wrapper .meter-score {
  position: absolute;
  z-index: 3;
  width: 100%;
  left: 0;
  bottom: 10.5%;
  text-align: center;
  color: #152033;
  font-size: 12px;
  font-weight: 600;
}

@media (min-width: 1700px) {
  .g-xxxl-5, .gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxxl-5, .gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
}
@media screen and (max-width: 1200px) {
  .annual-budgetbox .ab-divider {
    width: 140px;
  }
  .annual-budgetbox .savings-box .sed-lines {
    transform: rotate(-34deg);
    left: -253px;
    top: 86%;
    padding-left: 50px;
  }
  .annual-budgetbox .expenses-box .sed-lines {
    padding-left: 82px;
  }
  .annual-budgetbox .debt-box .sed-lines {
    transform: rotate(35deg);
    left: -234px;
    top: -26%;
    padding-left: 50px;
  }
}
@media screen and (max-width: 991.1px) {
  .w100-onlg {
    width: 100% !important;
  }
  .annual-budgetbox .savings-box .sed-lines {
    transform: rotate(-40deg);
    left: -244px;
    top: 82%;
    padding-left: 40px;
  }
  .annual-budgetbox .debt-box .sed-lines {
    transform: rotate(47deg);
    left: -210px;
    top: -42%;
    padding-left: 30px;
  }
}
@media screen and (max-width: 767.9px) {
  .annual-budgetbox {
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }
  .annual-budgetbox .ab-divider {
    width: 100%;
  }
  .annual-budgetbox .sed-lines {
    transform: rotate(0deg) !important;
    left: 0 !important;
    top: -40px !important;
    padding-left: 0 !important;
    width: auto !important;
  }
  .annual-budgetbox .sed-lines::after {
    display: none;
  }
  .annual-budgetbox .sed-badge {
    top: -30px;
    left: calc(50% - 30px);
  }
  .annual-budgetbox .sed-box {
    gap: 48px;
  }
  .annual-budgetbox .savings-box, .annual-budgetbox .expenses-box, .annual-budgetbox .debt-box {
    min-height: 148px;
    padding: 55px 18px 18px 18px;
  }
}
.texture-orange {
  filter: invert(51%) sepia(86%) saturate(811%) hue-rotate(0deg) brightness(105%) contrast(105%);
}

.bg-orange {
  background: #FFF9EB;
}

.texture-blue {
  filter: invert(39%) sepia(89%) saturate(7490%) hue-rotate(236deg) brightness(89%) contrast(110%);
}

.bg-blue-deem {
  background: #F4F9FF;
}

.texture-green {
  filter: invert(54%) sepia(47%) saturate(2658%) hue-rotate(113deg) brightness(106%) contrast(101%);
}

.bg-green-deem {
  background: #E8FFF3;
}

.texture-pink {
  filter: invert(53%) sepia(100%) saturate(7473%) hue-rotate(320deg) brightness(95%) contrast(122%);
}

.bg-pink-deem {
  background: #FFF2F8;
}

#apexchartsBarChart .apexcharts-toolbar {
  display: none;
}
#apexchartsBarChart svg path.apexcharts-bar-area:nth-child(1) {
  fill: #53A653 !important;
}
#apexchartsBarChart svg path.apexcharts-bar-area:nth-child(2) {
  fill: #EF4040 !important;
}
#apexchartsBarChart svg path.apexcharts-bar-area:nth-child(3) {
  fill: #F59E0C !important;
}/*# sourceMappingURL=index.css.map */