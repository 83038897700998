@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.inter-100 {font-weight: 100}
.inter-200 {font-weight: 200}
.inter-300 {font-weight: 300}
.inter-500 {font-weight: 500 !important}
.inter-600 {font-weight: 600 !important}
.inter-700 {font-weight: 700}
.inter-800 {font-weight: 800}
.inter-900 {font-weight: 900}
html{
    scrollbar-width: thin;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Inter", 'Segoe UI', 'Roboto', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    scrollbar-width: thin;
}
/*Start | Style*/ 
:root{
    --bs-primary: #0065FF;
    --bs-primary-rgb: 0, 101, 255;
    --bs-body-color: #152033;
    --bs-body-color-rgb: 21, 32, 51;
    --bs-success-rgb: 83, 166, 83;
}
$theme-b50: #E8E9EB;
$theme-b75: #9FA4AB;
$theme-b100: #777E89;
$theme-b200: #3D4656;
$theme-b300: #152033;
$theme-b400: #0F1624;
$theme-b500: #0D141F;

$theme-blue50: #E6F0FF;
$theme-blue75: #96C0FF;
$theme-blue: #0065FF;
$theme-gray: #F6F7F9;
$theme-e200: #F26060;
$theme-e300: #EF4040;
$theme-s300: #53A653;
$theme-s400: #3A743A;

$theme-w50: #FEF5E7;
$theme-w75: #FBD79B;
$theme-w100: #F9C772;
$theme-w200: #F7AE35;
$theme-w300: #F59E0C;
$theme-w400: #AC6F08;

$theme-cyan: #14B8A6;
$theme-pink: #EC4899;
$theme-purple: #6366F1;
$theme-yellow: #FACC15;

$theme-orange: #FF8800;

.l{box-shadow: 0 0 0 1px red}
.i{box-shadow: inset 0 0 0 1px blue}

.icon {
  stroke: unset;
  stroke-width: 0px;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: -0.1em;
}
.ts-0p7{transform: scale(0.7)}
.ts-0p8{transform: scale(0.8)}
.ts-0p9{transform: scale(0.9)}
.ts-1p1{transform: scale(1.1)}
.ts-1p2{transform: scale(1.2)}
.ts-1p3{transform: scale(1.3)}
.ts-1p4{transform: scale(1.4)}
.ts-1p5{transform: scale(1.5)}
.ts-1p6{transform: scale(1.6)}
.ts-1p7{transform: scale(1.7)}
.ts-1p8{transform: scale(1.8)}
.ts-1p9{transform: scale(1.9)}
.ts-2p0{transform: scale(2)}
.ts-2p1{transform: scale(2.1)}
.ts-2p2{transform: scale(2.2)}

.fs-10px{font-size: 10px}
.fs-11px{font-size: 11px}
.fs-12px{font-size: 12px}
.fs-13px{font-size: 13px}
.fs-14px{font-size: 14px}
.fs-16px{font-size: 16px}
.fs-18px{font-size: 18px}
.text-blue{color: $theme-blue !important}
.text-b100{color: $theme-b100 !important}
.text-b200{color: $theme-b200 !important}
.text-b300{color: $theme-b300 !important}
.text-success300{color: $theme-s300 !important}
.text-warning200{color: $theme-w200 !important}
.text-warning300{color: $theme-w300 !important}
.text-error300{color: $theme-e300 !important}
.text-cyan{color: $theme-cyan !important}
.text-pink{color: $theme-pink !important}
.text-purple{color: $theme-purple !important}
.text-yellow{color: $theme-yellow !important}
.text-orange{color: $theme-orange !important}
.text-green{color: $theme-s300 !important}
.text-red{color: $theme-e300 !important}
.text-hover-primary:hover{color: $theme-blue!important}

.bg-gray{background-color: $theme-gray}
.bg-gray-50{background-color: rgba($theme-gray, 0.50)}
.bg-b50{background-color: $theme-b50}
.bg-b75{background-color: $theme-b75 !important}
.bg-b100{background-color: $theme-b100 !important}
.bg-blue50{background-color: $theme-blue50 !important}
.bg-blue75{background-color: $theme-blue75 !important}

.bg-primary-10, .bg-blue-10{background-color: rgba($theme-blue, 0.10) !important}
.bg-warning-10{background-color: rgba($theme-w300, 0.10) !important}
.bg-cyan-10{background-color: rgba($theme-cyan, 0.10) !important}
.bg-pink-10{background-color: rgba($theme-pink, 0.10) !important}
.bg-purple-10{background-color: rgba($theme-purple, 0.10) !important}
.bg-yellow-10{background-color: rgba($theme-yellow, 0.10) !important}
.bg-orange-10{background-color: rgba($theme-orange, 0.10) !important}
.bg-orange-15{background-color: rgba($theme-orange, 0.15) !important}
.bg-green-10{background-color: rgba($theme-s300, 0.10) !important}
.bg-red-10{background-color: rgba($theme-e300, 0.10) !important}

.border-common{--bs-border-color: #EFF0F4}
.text-unof-1{text-underline-offset: 1px}
.text-unof-2{text-underline-offset: 2px}
.text-unof-3{text-underline-offset: 3px}
.text-link{
    text-decoration: none;
    color: $theme-blue;
    text-underline-offset: 4px;
    &:hover{
        color: #044bb5;
        text-decoration: underline;
    }
}
.minw-75px{min-width: 75px}
.minh-40px{min-height: 40px}
.minh-200px{min-height: 200px}
.minh-205px{min-height: 205px}
.minh-208px{min-height: 208px}
.minh-210px{min-height: 210px}
.minh-218px{min-height: 218px}
.minh-220px{min-height: 220px}
.maxw-120{max-width: 120px}
.maxw-150{max-width: 150px}
.maxw-160{max-width: 160px}
.maxw-180{max-width: 180px}
.maxw-200{max-width: 200px}
.maxw-220{max-width: 220px}
.maxw-560px{max-width: 560px}

.mmwh-25{ min-height: 25px; max-height: 25px; min-width: 25px; max-width: 25px}
.mmwh-45{min-height: 45px; max-height: 45px; min-width: 45px; max-width: 45px}
.mmwh-48{min-height: 48px; max-height: 48px; min-width: 48px; max-width: 48px}
.mmwh-120{ min-height: 120px; max-height: 120px; min-width: 120px; max-width: 120px}

.mmw-16{min-width: 16px; max-width: 16px}
.mmw-100{min-width: 100px; max-width: 100px}
.mmw-200{min-width: 200px; max-width: 200px}
.mmw-220{min-width: 220px; max-width: 220px}
.mmw-240{min-width: 240px; max-width: 240px}
.mmw-280{min-width: 280px; max-width: 280px}
.mmh-15{min-height: 15px; max-height: 15px}
.mmh-16{min-height: 16px; max-height: 16px}
.py-2px{padding-top: 2px; padding-bottom: 2px}
.py-3px{padding-top: 3px; padding-bottom: 3px}
.py-5px{padding-top: 5px; padding-bottom: 5px}
.py-6px{padding-top: 6px; padding-bottom: 6px}
.py-7px{padding-top: 7px; padding-bottom: 7px}
.p-20px{padding: 20px}

.img-cover{object-fit: cover; object-position: center}
.rounded-10px{border-radius: 10px !important}
.rounded-20px{border-radius: 20px}
.shadow-common{box-shadow: 0 4px 5px rgba(0, 0, 0, 0.03), 0 1px 3px rgba(0, 0, 0, 0.02)}
.dropdown-item-common {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #3D4656;
    position: relative;
    &:active {
        background: #0065FF;
        color: #ffffff;
    }
}
.opacity-10{opacity: 0.1}
.opacity-15{opacity: 0.15}

.form-control-fin{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    height: 50px;
    border-radius: 8px;
    background-color: $theme-gray;
    border-color: $theme-gray;
    &::placeholder{
        color: $theme-b75;
    }
    &:focus{
        background-color: $theme-gray;
    }
}
.form-search{
    padding-left: 45px !important;
    vertical-align: -.125em;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23757575' xmlns='http://www.w3.org/2000/svg'><path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0'/></svg>");
    background-repeat: no-repeat;
    background-size: 1.15rem 1.15rem;
    background-position: 13px center;
}
.control-withicon{
    position: relative;
    .control-icon{
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        position: absolute;
        border-radius: 4px;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
        .icon{
            font-size: 20px;
            color: $theme-blue;
        }
    }
    .form-control-fin{
        padding-left: 60px;
        
    }
    &:has(.control-onoffpassword){
        .form-control-fin{
            padding-right: 52px;
        }
    }
    .control-onoffpassword{
        position: absolute;
        padding: 0;
        border: none;
        right: 12px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        background-color: transparent;
        color: $theme-b75;
        &:hover, &:focus{
            color: $theme-b500;
        }
    }
}
.btn-primary {
    --fin-primary-rgb: 49,132,253;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: #{$theme-blue};
    --bs-btn-border-color: #{$theme-blue};
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #0a58ca;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: var(--fin-primary-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #044bb5;
    --bs-btn-active-border-color: #044bb5;
}
.btn-outline-primary {
    --bs-btn-color: #{$theme-blue};
    --bs-btn-border-color: #{$theme-blue};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{$theme-blue};
    --bs-btn-hover-border-color: #{$theme-blue};
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #044bb5;
    --bs-btn-active-border-color: #044bb5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #{$theme-blue};
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #{$theme-blue};
    --bs-gradient: none;
}
.btn-fin{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    height: 50px;
    border-radius: 8px;
}
.btn-normal{
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    --bs-border-radius: 0.625rem;
    --bs-btn-padding-x: 0.885rem;
}
.form-control-select{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    height: 50px;
    border-radius: 8px;
    background-color: #F6F7F9;
    border-color: #F6F7F9;
    div[class*="-control"]{
        border: none !important;
        outline: none;
        box-shadow: none;
        background: #fff;
        min-height: 50px;
        border-radius: 10px;
        padding-left: 6px;
        &:focus-within{
            box-shadow: 0 0 0 1px #86b7fe;
        }
    }
}

/* Start | Asidebar Style */
.fin-aside{
    position: fixed;
    width: 240px;
    height: 100%;
    left: 0;
    top: 0;
    background: $theme-gray;
    display: flex;
    flex-direction: column;
    z-index: 11;
    transition: 250ms;
    .fin-logobox{
        padding: 0 16px;
        min-height: 66px;
        height: 66px;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &::before{
            content: '';
            width: calc(100% - 32px);
            height: 1px;
            background-color: $theme-b50;
            position: absolute;
            left: 16px;
            bottom: 0;
        }
        a{
            width: 100%;
            display: inline-flex;
            align-items: center;
            padding: 6px 16px;
        }
        .fin-menutoggler{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            padding: 0;
            border: none;
            background-color: #ffffff;
            box-shadow: 0 0 0 1px $theme-b50;
            right: -12px;
            top: 21px;
            color: $theme-b100;
            font-size: 22px;
            overflow: hidden;
            z-index: 2;
            transition: 300ms;
            &:hover, &:focus-visible{
                color: $theme-blue;
                box-shadow: 0 0 4px 1px $theme-blue;
            }
        }
    }
    .fin-asideinner{
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        padding: 0 16px 16px;
        scrollbar-width: thin;
    }
    .fin-asidelabel{
        text-transform: uppercase;
        font-size: 14px;
        color: $theme-b75;
        font-weight: 500;
        padding-left: 16px;
        margin-bottom: 12px;
    }
    .fin-mainmenu{
        margin-bottom: 50px;
    }
    .fin-othermenu{
        margin-top: auto;
    }
    .fin-asidelink{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 40px;
        padding: 5px 16px;
        border-radius: 6px;
        gap: 12px;
        transition: 200ms;
        color: $theme-b200;
        text-decoration: none;
        .icon{
            width: 21px;
            height: 21px;
        }
        &:not(:last-child){
            margin-bottom: 10px;
        }
        &:hover{
            color: $theme-blue;
            background: rgba($theme-blue, 0.08);
        }
        &.active{
            color: $theme-blue;
            font-weight: 600;
            background: #fff;
            box-shadow: 0 2px 4px rgb(0, 0, 0, 0.11);
        }
        &[href="/login"]{
            color: $theme-e300;
            font-weight: 600;
            background: #fff;
            box-shadow: 0 2px 4px rgb(0, 0, 0, 0.11);
            &:hover{
                opacity: 0.75;
            }
            &:active{
                opacity: 1;
            }
        }
    }
}
/* End | Asidebar */

/* Start | Header */
.fin-header{
    width: 100%;
    height: 66px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    padding-left: calc(240px + 24px + 10px);
    padding-right: 24px;
    z-index: 10;
    box-shadow: calc(240px + 24px) 0 1px 1px #EFF0F4;
    transition: 250ms;
    .fin-headerleft{
        display: inline-flex;
        align-items: center;
        font-size: 20px;
        color: $theme-b300;
        font-weight: 500;
        gap: 8px;
    }
    .fin-headerright{
        display: flex;
        align-items: center;
        gap: 24px;
    }
    .dropdown-notifications{
        .btn-bell{
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background-color: $theme-gray;
            border: none;
            position: relative;
            font-size: 19px;
            &::before{
                content: '';
                position: absolute;
                width: 7px;
                height: 7px;
                border-radius: 6px;
                background-color: $theme-e200;
                z-index: 1;
                top: 9px;
                right: 11px;
                visibility: hidden;
            }
            &.active{
                &::before{
                    visibility: visible;
                }
            }
        }
        .dropdown-menu{
            width: 440px;
            border-radius: 16px;
            border: none;
            margin-top: 12px !important;
            padding: 0;
            overflow: hidden;
        }
        .notif-card{
            display: flex;
            flex-direction: column;
            .notif-header{
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #EBEDF0;
                strong{
                    color: $theme-b300;
                    font-size: 20px;
                    font-weight: 600;
                }
                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 28px;
                    height: 28px;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 600;
                    color: $theme-blue;
                    background-color: $theme-blue50;
                }
                .notif-markread{
                    font-size: 16px;
                    font-weight: 600;
                    color: $theme-blue;
                    background-color: transparent;
                    border: none;
                    padding: 2px 0;
                    text-underline-offset: 4px;
                    &:hover{
                        opacity: 0.80;
                        text-decoration: underline;
                    }
                }
            }
            .notif-body{
                overflow: hidden;
                overflow-y: auto;
                max-height: calc(100vh - 220px);
                .dropdown-item{
                    text-decoration: none;
                    display: flex;
                    gap: 12px;
                    padding: 20px;
                    position: relative;
                    white-space: normal;
                    &:not(:last-child){
                        &::after{
                            content: '';
                            position: absolute;
                            width: calc(100% - 20px);
                            left: 20px;
                            bottom: 0px;
                            border-bottom: 1px solid #EFF0F4;
                        }
                    }
                    &.notif-new{
                        .notif-icon{
                            position: relative;
                            &::before{
                                visibility: visible;
                            }
                        }
                    }
                    &:active{
                        background: $theme-blue;
                        *{
                            color: #ffffff !important;
                        }
                    }
                    .notif-icon{
                        position: relative;
                        color: $theme-blue;
                        &::before{
                            content: '';
                            position: absolute;
                            left: -4px;
                            top: -4px;
                            width: 8px;
                            height: 8px;
                            border-radius: 8px;
                            background-color: currentColor;
                            visibility: hidden;
                        }
                        .notif-iconmask{
                            width: 48px;
                            min-width: 48px;
                            height: 48px;
                            overflow: hidden;
                            padding: 8px;
                            background-color: $theme-b50;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    p{
                        font-size: 16px;
                        color: #091E42;
                        margin-bottom: 10px;
                    }
                    small{
                        font-size: 14px;
                        font-weight: 600;
                        color: $theme-b100;
                    }
                }
            }
        }

    }
    .dropdown-profile{
        .dropdown-toggle{
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: none;
            display: flex;
            align-items: center;
            gap: 8px;
            &[aria-expanded=true]{
                .avatar-mask{
                    padding: 2px;
                    background-color: rgba($theme-blue, 0.75);
                }
            }
            .avatar-mask{
                overflow: hidden;
                min-width: 40px;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                padding: 1px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            strong{
                font-size: 14px;
                font-weight: 600;
            }
        }
        .dropdown-menu{
            width: 250px;
            border-radius: 16px;
            border: none;
            margin-top: 12px !important;
            padding: 0;
            .dropdown-item{
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 20px;
                font-size: 14px;
                font-weight: 500;
                color: $theme-b200;
                position: relative;
                &:active{
                    background: $theme-blue;
                    color: #ffffff;
                }
                .icon{
                    font-size: 19px;
                }
                &.arrow{
                    &::after{
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-top: 2px solid currentColor;
                        border-right: 2px solid currentColor;
                        opacity: 0.6;
                        right: 20px;
                        transform: rotate(45deg);
                        top: calc(50% - 5px);
                    }
                }
            }
        }
    }
}
.global-search{
    position: relative;
    width: 262px;
    .search-icon{
        position: absolute;
        pointer-events: none;
        font-size: 18px;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        color: $theme-b100;
    }
    .form-control{
        border-radius: 10px;
        height: 40px;
        font-weight: 500;
        padding-left: 40px;
        padding-right: 64px;
        background-color: $theme-gray;
        border-color: $theme-gray;
        font-size: 14px;
        &:focus{
            border-color: rgba($theme-blue, 0.25);
        }
        &::placeholder{
            color: $theme-b100;
        }
    }
    .command-button{
        border: none;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 4px;
        border-radius: 8px;
        background: transparent;
        top: 50%;
        height: calc(100% - 8px);
        transform: translateY(-50%);
        color: $theme-b300;
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: #ffffff;
        padding: 2px 8px;
        font-size: 17px;
        line-height: 1;
        span{
            font-size: 15px;
            font-family: "Inter", 'Segoe UI', 'Roboto', sans-serif;
            font-weight: 500;
        }
    }
}
/* End | Header */

.fin-mainwrapper{
    padding-top: 66px;
    padding-left: 240px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
    transition: 250ms;
    .fin-innerwrapper{
        height: auto;
        padding: 24px 12px;
        .row-inner{
            margin-left: 0;
            margin-right: 0;
        }
    }
    .fin-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 24px;
        border-top: 1px solid #EFF0F4;
        height: 56px;
        background: #fff;
        margin-top: auto;
        color: $theme-b100;
        font-weight: 500;
        font-size: 14px;
        box-shadow: 0 -2px 4px rgb(0, 0, 0, 0.01), 0 -7px 7px rgb(0, 0, 0, 0.01), 0 -12px 9px rgb(0, 0, 0, 0.01);
        .fin-footerlink{
            display: flex;
            gap: 6px 24px;
            a{
                text-decoration: none;
                transition: 250ms;
                color: $theme-b100;
                &:hover{
                    color: $theme-blue;
                }
            }
        }
    }
}

/*Start | Menu Responsive*/
@media screen and (min-width: 1024.9px){
    .body-menuactive{
        .fin-aside{
            left: -228px;
            .fin-logobox .fin-menutoggler{
                transform: rotate(180deg);
            }
            .fin-asideinner{
                overflow-y: hidden;
            }
        }
        .fin-header{
            padding-left: 34px;
            box-shadow: 24px 0 1px 1px #EFF0F4;
        }
        .fin-mainwrapper {
            padding-left: 0;
        }
    }
}
@media screen and (max-width: 1024px){
    .fin-aside{
        left: -228px;
        .fin-logobox .fin-menutoggler{
            transform: rotate(180deg);
        }
        .fin-asideinner{
            overflow-y: hidden;
        }
    }
    .fin-header{
        padding-left: 34px;
        box-shadow: 24px 0 1px 1px #EFF0F4;
    }
    .fin-mainwrapper {
        padding-left: 0;
    }
    .body-menuactive{
        .fin-aside{
            left: 0;
            .fin-logobox .fin-menutoggler{
                transform: rotate(0deg);
            }
            .fin-asideinner{
                overflow-y: auto;
            }
        }
    }
}
/*End | Menu Responsive*/ 

.simulator-bg{
    background-color: rgba($theme-blue50, 0.30);
    border-top: 2px solid $theme-blue75;
}
.label-withicon{
    position: relative;
    display: block;
    label{
        display: flex;
        gap: 10px;
        font-size: 14px;
        color: $theme-b100;
        font-weight: 500;
        .icon{
            min-width: 20px;
            width: 20px;
            height: 20px;
        }
        span{
            align-self: center;
        }
    }
    .label-inputwrapper{
        position: relative;
        padding-left: 30px;
        input{
            width: 100%;
            height: 38px;
            padding: 0 20px 0 0;
            border: 0;
            border-bottom: 1px solid $theme-b75;
            font-size: 16px;
            font-weight: 600;
            color: $theme-b300;
            outline: none;
            background: transparent;
            &:focus{
                border-bottom: 1px solid $theme-blue;
            }
        }
    }
}

.table-responsive{
    padding: 1.25px 1.25px 2px 1.25px;
    scrollbar-width: thin;
}
.table-common{
    border-collapse: collapse;
    border-radius: 12px;
    box-shadow: 0 0 0 1.15px #EFF0F4;
    width: 100%;
    thead{
        background-color: #E6F0FF4D;
        th,td{
            font-size: 12.5px;
            line-height: 18px;
            font-weight: 600;
            padding: 12px 20px;
            border-bottom: 1px solid #EFF0F4;
            // color: $theme-b100;
            color: rgba($theme-blue, 0.72);
            vertical-align: middle;
            &:first-child{
                border-radius: 12px 0 0 0;
            }
            &:last-child{
                border-radius: 0 12px 0 0;
            }
        }
    }
    tbody{
        font-size: 14px;
        tr{
            &:not(:last-child){
                border-bottom: 1px solid #EFF0F4;
                td{
                    &:first-child, &:last-child {
                        &::before {
                            content: '';
                            width: 20px;
                            height: 2px;
                            display: block;
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            background-color: #fff;
                        }
                    }
                    &:last-child {
                        &::before {
                            left: auto;
                            right: 0;
                        }
                    } 
                }
            }
            td{
                color: $theme-b200;
                padding: 15px 20px;
                font-weight: 500; 
                position: relative;
            }
        }
    }
    tfoot{
        background-color: #E6F0FF4D;
        font-size: 14px;
        td{
            color: $theme-b300;
            padding: 15px 20px;
        }
    }
}
.link-newstext{
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: currentColor;
    text-decoration: none;
    &:hover{
        color: $theme-blue;
    }
}

/* Start | Login */ 
.entry-section{
    position: relative;
    display: flex;
    min-height: 100vh;
    .entry-leftbox{
        width: 100%;
        max-width: 600px;
        background-color: $theme-gray;
        padding: 68px;
        position: relative;
        .entry-bgpattern{
            position: absolute;
            z-index: 0;
            pointer-events: none;
            user-select: none;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            top: 0;
            left: 0;
        }
        .entry-logo{
            position: relative;
        }
        h1{
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: $theme-b300;
            span{
                color: $theme-blue;
            }
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            color: $theme-b200;
            text-align: center;
            margin: 0;
        }
    }
    .entry-rightbox{
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px;
        .entry-controls{
            width: 100%;
            max-width: 540px;
        }
        .er-heading{
            font-size: 36px;
            font-weight: 600;
            color: $theme-b300;
        }
        .er-paragraph{
            font-size: 15px;
            color: $theme-b100;
        }
    }
}
.entry-pointbox{
    text-align: center;
    max-width: 90px;
    span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 2px 4px rgb(0, 0, 0, 0.10);
        margin: 0 auto;
        img{
            width: 30px;
            display: block;
            filter: invert(47%) sepia(74%) saturate(7494%) hue-rotate(209deg) brightness(98%) contrast(113%);
        }
    }
    small{
        margin-top: 8px;
        display: block;
        font-size: 12px;
        color: $theme-b100;
        font-weight: 500;
    }
}
.or-line{
    position: relative;
    overflow: hidden;
    text-align: center;
    display: block;
    color: $theme-b75;
    &::before, &::after{
        content: '';
        width: calc(50% - 24px);
        height: 1px;
        top: 50%;
        display: block;
        position: absolute;
        background-color: #EFF0F4;
    }
    &::after{
        left: auto;
        right: 0;
    }
}
@media screen and (max-height: 768px){
    .entry-section{
        .entry-leftbox{
            max-width: 500px;
            padding: 24px 60px;
            .ratio-1x1{
                width: 64%;
                margin: 0 auto;
            }
            h1{
                font-size: 28px;
                margin-bottom: 10px;
            }
            p{
                font-size: 14px;
            }
        }
    }
}
/* End | Login */


.nav-pills-common{
    border-radius: 10px;
    background-color: $theme-gray;
    padding: 6px 10px;
    gap: 6px 12px;
    .nav-link{
        --bs-nav-link-padding-y: 3.5px;
        font-size: 14px;
        color: $theme-b100;
        font-weight: 500;
        border-radius: 6px;
        &:hover{
            color: $theme-b300;
            box-shadow: inset 0 0 0 1px $theme-b75;
        }
        &.active{
            background-color: #fff;
            color: $theme-b300;
            box-shadow: none;
            pointer-events: none;
        }
    }
}
.nav-pills-tabs{
    gap: 10px;
    .nav-link{
        background-color: rgba($theme-gray, 1);
        font-size: 14px;
        color: $theme-b100;
        font-weight: 500;
        border-radius: 6px;
        &.active{
            pointer-events: none;
        }
    }
}
.input-group-datepicker{
    position: relative;
    .form-control{
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        color: $theme-b300;
        border-radius: 10px;
        padding-left: 44px;
        border-color: $theme-b50;
        &:focus{
            border-color: #86b7fe;
        }
    }
    .icon{
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 6;
        font-size: 21px;
        pointer-events: none;
    }
    .btn{
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        color: $theme-b300;
        border-radius: 10px;
        border-color: $theme-b50;
        &:focus{
            border-color: #86b7fe;
            box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
        }
    }
    .dropdown-item{
        font-size: 14px;
        font-weight: 500;
        color: $theme-b200;
        &:active{
            color: #fff;
        }
    }
}

/*Start | Chart Style*/
.fin-donutchart{
    position: relative;
    min-width: 200px;
    width: 200px;
    height: 200px;
    .fin-innerdonuttext{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        p{
            font-size: 13px;
            font-weight: 500;
            white-space: nowrap;
            color: $theme-b100;
            margin-bottom: 5px;
        }
        h4{
            font-size: 29px;
            font-weight: 700;
            line-height: 1;
            margin: 0;
        }
    }
} 
.fin-allfillchart{
    min-width: 160px;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    position: relative;
}
.fin-donutdivider{
    width: 1px;
    height: 160px;
    background-color: $theme-b50;
}
.apexcharts-toolbar {
    z-index: 2 !important;
}
/*End | Chart Style*/

.pagination{
    align-items: center;
    font-family: "Inter", 'Segoe UI', 'Roboto', sans-serif;
    .page-item, .ant-pagination-item{
        font-family: "Inter", 'Segoe UI', 'Roboto', sans-serif;
        height: auto;
        border-radius: 2.5px;
        line-height: normal;
        margin-inline-end: 6px;
        font-weight: 600;
        background-color: transparent;
        &.page-item-prev, &.page-item-next{
            height: auto;
            .page-link {
                background: transparent;
                border-color: transparent;
            }
        }
        .page-link, a{
            position: relative;
            display: block;
            padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
            font-size: var(--bs-pagination-font-size);
            color: var(--bs-pagination-color);
            text-decoration: none;
            background-color: var(--bs-pagination-bg);
            border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            border-radius: 2.5px;
            font-size: 12px;
            font-weight: 600 !important;
            padding: 6px;
            text-align: center;
            min-width: 32px;
            border-color: $theme-blue;
            &:hover{
                color: $theme-blue;
                background-color: rgba($theme-blue, 0.1);
            }
        }
    }
    .ant-pagination-prev, .ant-pagination-next{
        height: auto;
        line-height: normal;
        border-radius: 2.5px;
        .page-link, a {
            font-size: 12px;
            font-weight: 600;
            background: transparent;
            border-color: transparent;
            border-radius: 2.5px;
        }
    }
    .ant-pagination-item-active{
        border-color: transparent;
        .page-link, a{
            background-color: $theme-blue;
            color: #fff;
        }
    }
    .ant-pagination-disabled{
        pointer-events: none;
    }
}




// 
.row-cols-border{
    .col{
        border: 1px solid $theme-b50;
        margin-right: -1px;
        position: relative;
        &.col-indicator{
            cursor: pointer;
            &:hover{  
                background-color: #F6F7F9;
            }
        }
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 6px;
            bottom: -1px;
            left: 0;
            background-color: transparent;
            transition: 300ms;
        }
        &::after{
            content: '';
            width: 0;
            height: 0;
            left: calc(50% - 10px);
            bottom: -9px;
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid transparent;
            transition: 100ms;
        }
        &.col-myporfolio::before{
            background-color: #F59E0C;
        }
        &.col-myporfolio::after{
            border-top: 10px solid #F59E0C;
        }
        &.col-nifty::before{
            background-color: #0065FF;
        }
        &.col-nifty::after{
            border-top: 10px solid #0065FF;
        }
    }
}
.profile-feature-card{
    position: relative;
    padding: 20px 60px 20px 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgb(0, 0, 0, 0.03), 0 1px 3px rgb(0, 0, 0, 0.02);
    display: flex;
    gap: 15px;
    height: 100%;
    .pfc-icon{
        min-width: 45px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: #f9f9f9;
        .icon{font-size: 24px}
    }
    .pfc-content{
        h5{
            font-size: 18px;
            font-weight: 600;
            color: $theme-b300;
            margin-bottom: 4px;
        }
        p{
            color: $theme-b100;
            font-size: 14px;
            margin: 0;
        }
        .pfc-link{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 26px;
            height: 26px;
            border-radius: 26px;
            background-color: $theme-gray;
            color: $theme-b200;
            font-size: 24px;
            position: absolute;
            right: 20px;
            top: calc(50% - 10px);
            transition: 300ms;
            border: none;
            padding: 0;
            &:hover, &:focus-visible{
                background-color: $theme-blue;
                color: #fff;
            }
        }
    }
}
.checked-medium-text{
    .form-check{
        cursor: pointer;
        .form-check-input:checked ~ .form-check-label{
            font-weight: 500;
            color: $theme-b300;
        }
    }
}

@media screen and (min-width: 992px){
    .minw-md-280{min-width: 280px}
    .minw-md-300{min-width: 300px}
    .minw-md-320{min-width: 320px}
}



.css-tooltip{
    --bg-ttp: #000000;
    position: relative;
    font-family: Arial;
    outline: none;
    &[aria-label="Copied"]{
        --bg-ttp: #{$theme-s400};
        color: $theme-s400;
    }
    &:focus-visible{
      opacity: 0.75;
    }
    &:hover, &:focus-visible{
      &::before, &::after{
        visibility: visible; 
        opacity: 1;
      }
    }
    &::before{
      content: attr(aria-label);
      position: absolute;
      min-width: 40px;
      white-space: nowrap;
      font-size: 13px;
      text-align: center;
      min-height: 28px;
      background-color: var(--bg-ttp);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      line-height: 1;
      border-radius: 6px;
      text-shadow: none;
      padding: 7px 10px;
      left: 50%;
      bottom: calc(100% + 8px);
      transform: translate(-50%, 0);
      z-index: 2;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }
    &::after{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid var(--bg-ttp);
      left: 50%;
      bottom: calc(100% + 2px);
      transform: translate(-50%, 0);
      z-index: 2;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }
}

.card-detailing{
    position: relative;
    border-radius: 12px;
    background-color: $theme-gray;
    padding: 18px;
    height: 100%;
    box-shadow: 0 0 0 1px $theme-gray;
    .card-icon{
        min-width: 40px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: rgb(0, 0, 0, 0.1);
        font-size: 24px;
    }
    h5{
        padding-top: 1px;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 3px;
    }
    p{
        font-size: 12.5px;
        font-weight: 500;
        color: $theme-b100;
    }
    hr{
        opacity: 0.15;
    }
    &.card-detailtheme{
        background-color: #F2F6FF;
        box-shadow: 0 0 0 1px rgba(#5D89EE, 0.15);
        .card-icon{
            background-color: #E0EAFF;
            color: #5D89EE;
        }
        h5, hr{color: #5D89EE}
    }
    &.card-detailpink{
        background-color: #FFF2F3;
        box-shadow: 0 0 0 1px rgba(#EA828B, 0.15);
        .card-icon{
            background-color: #FFE5E7;
            color: #EA828B;
        }
        h5, hr{color: #EA828B}
    }
    &.card-detailpurple{
        background-color: #FAF4FF;
        box-shadow: 0 0 0 1px rgba(#BE8EE5, 0.15);
        .card-icon{
            background-color: #F3E5FF;
            color: #BE8EE5;
        }
        h5, hr{color: #BE8EE5}
    }
    &.card-detailgreen{
        background-color: #EFFFF7;
        box-shadow: 0 0 0 1px rgba(#83C6A4, 0.15);
        .card-icon{
            background-color: #DBF9EA;
            color: #83C6A4;
        }
        h5, hr{color: #83C6A4}
    }
    &.card-detailgold{
        background-color: #FFFDF3;
        box-shadow: 0 0 0 1px rgba(#DEC963, 0.15);
        .card-icon{
            background-color: #F9F3CE;
            color: #DEC963;
        }
        h5, hr{color: #DEC963}
    }
}

.annual-budgetbox{
    position: relative;
    display: flex;
    align-items: center;
    .annual-incomebox{
        width: 162px;
        height: 162px;
        border-radius: 162px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        z-index: 2;
        position: relative;
        border: 14px solid #14B8A6;
        box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.10);
        small{
            color: #152033;
            font-size: 14px;
        }
        h6{
            color: #14B8A6;
            font-size: 18px;
            margin-top: 5px;
        }
    }
    .ab-divider{
        width: 180px;
    }
    .sed-box{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .ab-linebox{
        position: relative;
        padding-top: 4px;
        small{
            font-size: 11px;
            color: #3D4656;
            white-space: nowrap;
        }
        h6{
            font-size: 13px;
            font-weight: 700;
            color: #152033;
            margin: 0;
        }
        &::before, &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            left: 0;
            top: 0;
            border-radius: 10px;
            background-color: #c6c6cc;
        }
        &::after{
            width: 34px;
            background-color: #3a3a3b;
            z-index: 1;
        }
        &.ab-success {
            &::before{background-color: #B8DBB8}
            &::after{background-color: #70B570}
        }
        &.ab-error {
            &::before{background-color: #F8B1B1}
            &::after{background-color: #F26060}
        }
        &.ab-warning {
            &::before{background-color: #FBD79B}
            &::after{background-color: #F7AE35}
        }
    }
    .sed-lines{
        font-size: 14px;
        position: absolute;
        text-align: center;
        color: #152033;
        border-bottom: 2px dashed currentColor;
        padding: 4px 0;
        &::after{
            content: '';
            position: absolute;
            right: 0;
            top: calc(100% - 5px);
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 9px solid currentColor;
        }
    }
    .sed-badge{
        width: 60px;
        height: 60px;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #999;
        border: 3px solid currentColor;
        position: absolute;
        top: calc(50% - 30px);
        left: -30px;
        background-color: #fff;
        box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.10);
    }
    .savings-box, .expenses-box, .debt-box{
        position: relative;
        min-height: 148px;
        border-radius: 10px;
        padding: 18px 18px 18px 55px;
    }
    .savings-box{
        background-color: #EEF6EE;
        .sed-lines{
            border-bottom: 2px dashed #53A653;
            transform: rotate(-21deg);
            width: 240px;
            left: -265px;
            top: 64%;
            &::after{
                color: #53A653;
            }
        }
        .sed-badge{
            color: #53A653;
        }
    }
    .expenses-box{
        background-color: #FDECEC;
        .sed-lines{
            border-bottom: 2px dashed #EF4040;
            transform: rotate(0deg);
            width: 200px;
            left: -228px;
            top: calc(50% - 30px);
            padding-left: 50px;
            &::after{
                color: #EF4040;
            }
        }
        .sed-badge{
            color: #EF4040;
        }
    }
    .debt-box{
        background-color: #FEF5E7;
        .sed-lines{
            border-bottom: 2px dashed #F59E0C;
            transform: rotate(21deg);
            width: 240px;
            left: -254px;
            top: -6%;
            padding-left: 50px;
            &::after{
                color: #F59E0C;
            }
        }
        .sed-badge{
            color: #F59E0C;
        }
    }
}

.goals-progressbar{
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // justify-content: space-between;
    gap: 12px;
    .progress{
        border-radius: 40px;
        height: 20px;
    }
    .progress-savings{
        background-color: #B8DBB8;
        .progress-bar{
            background-color: #70B570
        }
    }
    .progress-expenses{
        background-color: #F8B1B1;
        .progress-bar{
            background-color: #F26060
        }
    }
    .progress-debt{
        background-color: #FBD79B;
        .progress-bar{
            background-color: #F7AE35
        }
    }
}

.card-common{
    border-color: #EFF0F4;
    .card-header{
        border-color: #EFF0F4;
        padding-top: 16px;
        padding-bottom: 15px;
        .card-title{
            color: $theme-b300;
        }
    }
}
.card-listing{
    border: none;
    border-radius: 12px;
    overflow: hidden;
    .card-header{
        border: none;
        background-color: $theme-gray;
        border-radius: 12px 12px 0 0;
        small{
            text-transform: uppercase;
            font-size: 13px;
            color: $theme-b100;
            font-weight: 500;
        }
    }
    .card-body{
        border: 1px solid #EFF0F4;
        border-top: none;
        border-radius: 0 0 12px 12px;
        .list-group-item{
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            gap: 8px;
            color: $theme-b300;
            .list-group-icon{
                width: 24px;
                min-width: 24px;
                height: 24px;
                font-size: 17px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            span{
                font-size: 14px;
                font-weight: 500;
            }
            strong{
                align-self: center;
                font-size: 15px;
                line-height: 1;
                font-weight: 600;
            }
        }
    }
}

.meter-wrapper{
    width: 140px; 
    height: 140px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    padding: 2px;
    svg {
        width: 100%; 
        height: 100%;
        transform: rotate(135deg);
    }
    .meter-circle{
        fill: none;
    }
    .meter-outline {
        stroke: #F6F7F9;
    }
    .meter-range {
        stroke: currentColor;
        transition: 0.5s;
    }
    .meter-niddledot{
        background: currentColor;
        width: 14px;
        height: 14px;
        border-radius: 20px;
        position: absolute;
        bottom: 41%;
        left: calc(50% - 7px);
        transform: rotate(230deg);
        z-index: 2;
        transition: 0.5s;
    }
    .meter-niddle {
        position: absolute;
        bottom: 5px;
        left: calc(50% - 4px);
        width: 1px;
        border: 4px solid currentColor;
        border-right-color: transparent;
        border-left-color: transparent;
        border-width: 0px 4px 40px;
    }
    .meter-min, .meter-max{
        display: inline-block;
        position: absolute;
        z-index: 4;
        color: #777E89;
        font-size: 11.5px;
        line-height: 1;
        font-weight: 600;
        left: 13%;
        bottom: 4%;
    }
    .meter-max{
        left: unset;
        right: 11%;
    }
    .meter-score{
        position: absolute;
        z-index: 3;
        width: 100%;
        left: 0;
        bottom: 10.5%;
        text-align: center;
        color: #152033;
        font-size: 12px;
        font-weight: 600;
    }
}

@media (min-width: 1700px) {
    .g-xxxl-5, .gy-xxxl-5 {
        --bs-gutter-y: 3rem;
    }
    .g-xxxl-5, .gx-xxxl-5 {
        --bs-gutter-x: 3rem;
    }
}

@media screen and (max-width: 1200px){
    .annual-budgetbox {
        .ab-divider {
            width: 140px;
        }
        .savings-box .sed-lines {
            transform: rotate(-34deg);
            left: -253px;
            top: 86%;
            padding-left: 50px;
        }
        .expenses-box .sed-lines {
            padding-left: 82px;
        }
        .debt-box .sed-lines {
            transform: rotate(35deg);
            left: -234px;
            top: -26%;
            padding-left: 50px;
        }
    }
}
@media screen and (max-width: 991.1px){
    .w100-onlg{
        width: 100% !important;
    }
    .annual-budgetbox {
        .savings-box .sed-lines {
            transform: rotate(-40deg);
            left: -244px;
            top: 82%;
            padding-left: 40px;
        }
        .debt-box .sed-lines {
            transform: rotate(47deg);
            left: -210px;
            top: -42%;
            padding-left: 30px;
        }
    }
}
@media screen and (max-width: 767.9px){
    .annual-budgetbox {
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px;
        .ab-divider{
            width: 100%;
        }
        .sed-lines{
            transform: rotate(0deg) !important;
            left: 0 !important;
            top: -40px !important;
            padding-left: 0 !important;
            width: auto !important;
            &::after{
                display: none;
            }
        }
        .sed-badge {
            top: -30px;
            left: calc(50% - 30px);
        }
        .sed-box {
            gap: 48px;
        }
        .savings-box, .expenses-box, .debt-box {
            min-height: 148px;
            padding: 55px 18px 18px 18px;
        }

    }
}






// -----
.texture-orange{
    filter: invert(51%) sepia(86%) saturate(811%) hue-rotate(0deg) brightness(105%) contrast(105%);
}
.bg-orange{background: #FFF9EB}


.texture-blue{
    filter: invert(39%) sepia(89%) saturate(7490%) hue-rotate(236deg) brightness(89%) contrast(110%);
}
.bg-blue-deem{
    background: #F4F9FF
}

.texture-green{
    filter: invert(54%) sepia(47%) saturate(2658%) hue-rotate(113deg) brightness(106%) contrast(101%);
}
.bg-green-deem{
    background: #E8FFF3
}

.texture-pink{
    filter: invert(53%) sepia(100%) saturate(7473%) hue-rotate(320deg) brightness(95%) contrast(122%);
}
.bg-pink-deem{
    background: #FFF2F8;
}

#apexchartsBarChart{
    .apexcharts-toolbar{
        display: none;
    }
    svg{
        path.apexcharts-bar-area:nth-child(1){
            fill: #53A653 !important;
        }
        path.apexcharts-bar-area:nth-child(2){
            fill: #EF4040 !important;
        }
        path.apexcharts-bar-area:nth-child(3){
            fill: #F59E0C !important;
        }
    }
}